import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    if (publicKey) {
      setWalletAddress(publicKey.toString());
    }
  }, [publicKey]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const userData = { walletAddress };
    login(userData);
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleLogin} style={{ width: '100%', marginTop: '16px' }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="walletAddress"
            label="Wallet Address"
            name="walletAddress"
            autoComplete="wallet-address"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            disabled={!!publicKey} // Disable input if wallet address is pre-filled
            required
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
