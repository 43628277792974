// bondingCurve.js

// Calculate token price using an inverse bonding curve formula
export const calculateTokenPrice = (supply, a, b = 1) => {
  return a / (supply + b); // Inverse relationship with supply
};

// Calculate the total price for a given number of tokens starting from the current supply
export const calculateTotalPrice = (startSupply, tokens, a, b = 1) => {
  let totalPrice = 0;
  for (let i = 0; i < tokens; i++) {
    const currentSupply = startSupply - i;
    if (currentSupply > 0) {
      totalPrice += calculateTokenPrice(currentSupply, a, b);
    } else {
      break; // Stop if supply becomes zero or negative
    }
  }
  return totalPrice;
};

// Calculate the initial selling price for a given number of tokens starting from the current supply
export const calculateInitialSellingPrice = (startSupply, a, b = 1) => {
  return calculateTokenPrice(startSupply, a, b);
};

// Calculate the number of tokens received for a given amount of SOL
export const calculateTokensReceived = (amountInSol, startSupply, a, b = 1) => {
  let tokens = 0;
  let totalCost = 0;
  let currentSupply = startSupply;

  while (totalCost < amountInSol && currentSupply > 0) {
    const currentPrice = calculateTokenPrice(currentSupply, a, b);

    if (totalCost + currentPrice <= amountInSol) {
      totalCost += currentPrice;
      tokens++;
      currentSupply--; // Decrease supply
    } else {
      break; // Stop if the cost exceeds the amount of SOL provided
    }
  }

  return tokens;
};
