import React, { useState, useEffect } from 'react';
import { Box, Typography, Slide, useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';

const Ticker = () => {
  const [recentActivities, setRecentActivities] = useState([]);
  const [highlightMessage, setHighlightMessage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchRecentActivities = async () => {
      try {
        const response = await axios.get('https://backend.memebrewery.fun/api/recent-activities'); // Adjust the API endpoint as necessary
        const filteredActivities = response.data.activities.filter(activity => activity.amount > 350000);

        setRecentActivities(filteredActivities);

        if (filteredActivities.length > 0) {
          const latestActivity = filteredActivities[0];
          if (latestActivity.amount > 1000000) { // Highlight if over 1,000,000 tokens
            const formattedUser = formatWalletAddress(latestActivity.user);
            setHighlightMessage(`${formattedUser} just ${latestActivity.type} ${latestActivity.amount} tokens!`);
            setTimeout(() => setHighlightMessage(null), 5000);
          }
        }
      } catch (error) {
        console.error('Error fetching recent activities:', error);
      }
    };

    fetchRecentActivities();
    const intervalId = setInterval(fetchRecentActivities, 50000); // Fetch every 50 seconds

    return () => clearInterval(intervalId);
  }, []);

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 3)}...${address.slice(-3)}`;
  };

  return (
    <>
      {highlightMessage && (
        <Slide direction="down" in={!!highlightMessage} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: 'fixed',
              top: isMobile ? '10px' : '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.text.primary,
              padding: isMobile ? '8px 16px' : '10px 20px',
              borderRadius: '5px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
              zIndex: 1000,
              fontSize: isMobile ? '0.875rem' : '1rem',
              maxWidth: isMobile ? '90%' : 'auto',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {highlightMessage}
            </Typography>
          </Box>
        </Slide>
      )}

      {recentActivities.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            padding: isMobile ? '8px 0' : '10px 0',
            borderTop: `1px solid ${theme.palette.divider}`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: isMobile ? '0.875rem' : '1rem',
          }}
        >
          <Typography
            variant="body2"
            component="div"
            sx={{
              animation: 'scroll 15s linear infinite',
              '@keyframes scroll': {
                '0%': { transform: 'translateX(100%)' },
                '100%': { transform: 'translateX(-100%)' },
              },
              paddingLeft: isMobile ? '5%' : '10%', // Padding for better alignment on mobile
            }}
          >
            {recentActivities.map((activity, index) => (
              <span key={index} style={{ paddingRight: '20px' }}>
                {formatWalletAddress(activity.user)} {activity.type} {activity.amount} {activity.tokenName} tokens
              </span>
            ))}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Ticker;
