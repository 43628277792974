import React from 'react';
import { Pagination, Box } from '@mui/material';

const CustomPagination = ({ count, page, onChange }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        sx={{
          '& .MuiPaginationItem-root': {
            color: 'white', // White text for page numbers
            backgroundColor: '#424242', // Dark grey background
            borderRadius: '8px', // Rounded corners for a modern look
            margin: '0 4px', // Spacing between pagination items
            '&.Mui-selected': {
              backgroundColor: '#00bfa5', // Teal background for selected page
              color: 'white', // White text for selected page
              fontWeight: 'bold', // Bold text for selected page
            },
            '&:hover': {
              backgroundColor: '#757575', // Slightly lighter grey on hover
            },
          },
        }}
      />
    </Box>
  );
};

export default CustomPagination;
