import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Box, Button,
  Drawer, List, ListItem, ListItemText, Divider, useTheme, useMediaQuery
} from '@mui/material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const headerImage = '/images/BREWERY-LOGO-501.png';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { connected, publicKey } = useWallet();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [profile, setProfile] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ownsTokens, setOwnsTokens] = useState(false); // State to track token ownership
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const fetchProfile = async () => {
    
    setIsProfileLoading(true);
    try {
      console.log('User: ', user);
      if (user) {
        const token = localStorage.getItem('auth-token');
        if (!token) {
          console.error('No auth token found');
          setIsProfileLoading(false);
          return;
        }
        const response = await axios.get('https://backend.memebrewery.fun/api/profile', {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        const profile = response.data; // Destructure response data
  
        // Set the profile and any additional state you need
        const profileData = response.data;
        setProfile(profileData);
        setOwnsTokens(profile.data.ownsTokens);
        setIsProfileLoading(false);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      // Handle specific error cases
      if (error.response && error.response.status === 401) {
        // Token might be invalid or expired
        logout(); // Assuming logout clears the token and user state
      }
    } finally {
      setIsProfileLoading(false);
    }
  };
  
  useEffect(() => {
    if (connected && publicKey) {
      console.log('Wallet connected:', publicKey.toString());
    } else {
      console.log('Wallet is not connected properly.', { connected, publicKey });
    }
  }, [connected, publicKey]);


  useEffect(() => {
    if (user) {
      fetchProfile();
    }
  }, [user]);
    
  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#2c3e50' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Hamburger menu for mobile devices */}
          {/* {isMobile && ( */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          {/* )} */}

          {/* Meme Brewery Header Image */}
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Link to="/">
              <img
                src={headerImage}
                alt="Meme Brewery Logo"
                style={{
                  height: isMobile ? 70 : 100,
                  marginRight: isMobile ? 0 : 20,
                }}
              />
            </Link>
          </Box>

          {/* User Profile and Token Creation */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            {user && !isMobile && (
              <Button color="inherit" component={Link} to="/create-token" sx={{ marginRight: 2 }}>
                Create Token
              </Button>
            )}
          {user && (
              <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                {isProfileLoading ? (
                  <Avatar src={profile ? profile?.avatar : ''}><CircularProgress size={24} color="inherit" /></Avatar>
                ) : profile && profile.avatar ? (
                  <Avatar alt="Profile Avatar" src={profile?.avatar} />
                ) : (
                  <Avatar alt="Profile Avatar" src={profile?.avatar}>{user.walletAddress ? user.walletAddress[0].toUpperCase() : 'U'}</Avatar>
                )}
              </IconButton>
            )}
            <Menu
              anchorEl={profileAnchorEl}
              open={Boolean(profileAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
                Profile
              </MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>

          {/* Social Media and Wallet Buttons */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
              <IconButton href="https://x.com/memebrewe" target="_blank" color="inherit">
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://t.me/memebrew" target="_blank" color="inherit">
                <TelegramIcon />
              </IconButton>
              <WalletMultiButton sx={{ marginLeft: 2 }} />
            </Box>
          )}

          {/* Mobile Section */}
          {isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              <WalletMultiButton sx={{ marginLeft: 1, padding: '6px 12px' }} />
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ '& .MuiDrawer-paper': { width: 250 } }}
      >
        <Box
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
          sx={{ width: 250 }}
        >
          <List>
            <ListItem button component={Link} to="/">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/create-token">
              <ListItemText primary="Create Token" />
            </ListItem>
            {user && (
              <>
                <Divider />
                <ListItem button component={Link} to="/profile">
                  <Avatar alt="User Avatar" src={profile ? profile?.avatar : ''} />
                  <ListItemText primary={user.walletAddress} sx={{ marginLeft: 2 }} />
                </ListItem>
                <ListItem button onClick={logout}>
                  <ListItemText primary="Logout" />
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default Navbar;
