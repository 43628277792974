import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Box, Button, Avatar, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Profile() {
  const [profile, setProfile] = useState(null);
  const [tokenDetails, setTokenDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileAndTransactions = async () => {
      try {
        const token = localStorage.getItem('auth-token');
        const profileResponse = await axios.get(`https://backend.memebrewery.fun/api/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setProfile(profileResponse.data);

        let transactionsData = [];
        if (profileResponse.data.walletAddress) {
          const transactionsResponse = await axios.get(`https://backend.memebrewery.fun/api/transactions`, {
            params: { walletAddress: profileResponse.data.walletAddress },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          transactionsData = transactionsResponse.data.transactions || [];
          setTransactions(transactionsData);
        }

        if (profileResponse.data.tokens && profileResponse.data.tokens.length > 0) {
          const tokensResponse = await axios.get(`https://backend.memebrewery.fun/api/tokens/ids`, {
            params: { tokenIds: profileResponse.data.tokens.join(',') },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const tokensWithAmounts = await Promise.all(
            tokensResponse.data.tokens.map(async (token) => {
              const amountOwned = calculateTokenAmount(token.id, transactionsData);

               // Check if there's a 'finalize-creation' transaction for this token
              const createdByUser = transactionsData.some(
                (tx) => tx.tokenId === token.id && tx.type === 'finalize-creation'
              );

              return { ...token, amountOwned, createdByUser };
              // return { ...token, amountOwned };
            })
          );

          setTokenDetails(tokensWithAmounts);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching profile or transactions:', error);
        setIsLoading(false);
      }
    };

    fetchProfileAndTransactions();
  }, []);

  const calculateTokenAmount = (tokenId, transactions) => {
    if (!Array.isArray(transactions) || transactions.length === 0) {
      console.log('No transactions available for calculation.');
      return 0;
    }

    const tokenTransactions = transactions.filter(tx => tx.tokenId === tokenId);
    console.log('Filtered token transactions:', tokenTransactions);

    const totalAmount = tokenTransactions.reduce((acc, tx) => {
      if (tx.type === 'buy'  || tx.type === 'finalize-creation') {
        return acc + tx.amount;
      } else if (tx.type === 'sell') {
        return acc - tx.amount;
      }
      return acc;
    }, 0);

    return totalAmount;
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      setAvatar(acceptedFiles[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (avatar) {
      formData.append('avatar', avatar);
    }

    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/profile', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setProfile(response.data);
      setAvatar(null); // Reset the avatar after successful upload
      setMessage('Profile updated successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage('Profile update failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/webp': ['.webp'],
      'image/svg+xml': ['.svg'],
    },
  });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Button
          onClick={() => navigate('/')}
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          sx={{ fontWeight: 'bold', boxShadow: 2, borderRadius: 2, alignSelf: 'flex-start' }}
        >
          Back to Home
        </Button>
      </Box>

      {profile && (
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Avatar
            src={profile.avatar}
            alt="User Avatar"
            sx={{
              width: 120,
              height: 120,
              margin: '0 auto',
              boxShadow: 3,
              border: '4px solid',
              borderColor: 'primary.main',
            }}
          />
        </Box>
      )}

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Box
              {...getRootProps()}
              mt={2}
              mb={2}
              p={3}
              border="2px dashed grey"
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: 2,
                bgcolor: avatar ? 'primary.light' : 'background.default',
                color: avatar ? 'primary.contrastText' : 'text.secondary',
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {avatar ? avatar.name : 'Drag and drop an avatar here, or click to select one'}
              </Typography>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, fontWeight: 'bold', boxShadow: 2, borderRadius: 2 }}
            >
              Update Profile
            </Button>
          </form>

          <Grid container spacing={2} sx={{ mt: 4 }}>
            {tokenDetails.map((token) => (
              <Grid item xs={12} sm={6} md={4} key={token.id}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 2,
                    borderRadius: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={token.name}
                    height="140"
                    image={token.icon}
                    sx={{ objectFit: 'contain', width: '50%', mt: 2 }}
                  />
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                      {token.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Amount Owned: {token.amountOwned}
                    </Typography>
                     {/* Check if the current user created this token */}
                      {token.createdByUser && (
                        <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
                          Created by you on {new Date(token.createdAt).toLocaleDateString()}
                        </Typography>
                      )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={message.startsWith('Profile updated successfully!') ? 'success' : 'error'}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Profile;
