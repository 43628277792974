import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { Container, Grid, Card, CardContent, Typography, Box, Avatar, Button, Pagination, IconButton } from '@mui/material';
import {
  Container, Grid, Card, CardContent, Typography, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, Snackbar, Alert, Box, MenuItem, Select, FormControl, InputLabel,
  AppBar, Toolbar, Button, Avatar, List, ListItem, ListItemAvatar, ListItemText, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Pagination, 
  InputAdornment, Tab, Tabs
} from '@mui/material';
import { Connection, Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LanguageIcon from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CustomPagination from './CustomPagination';
import { fetchAndCacheSolPrice } from '../utils/solPriceCache';
import { useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { calculateTokensReceived, calculateTokenPrice, calculateTotalPrice } from './bondingCurve';
import {
  Line, Bar, Doughnut, Pie, Radar, PolarArea, Bubble, Scatter
} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale, LinearScale, PointElement, LineElement, BarElement,
  Title, Tooltip, Legend, ArcElement, RadialLinearScale
} from 'chart.js';
import TokenDetailsTabs from './TokenDetailsTabs';

ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title,
  Tooltip, Legend, ArcElement, RadialLinearScale
);

const A = 75;
const N = 50000;


//  this is new css 
const TokenDescription = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  maxHeight: '2.8em',
  color: theme.palette.text.secondary,
}));

const BannerImage = styled('img')({
  width: '200px',
  height: 'auto',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
});

const SidebarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '10px',
  borderRadius: '15px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  marginBottom: '10px',
}));

const SidebarTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: theme.palette.primary.main,
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#00bfa5',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const TokenCard = styled(Card)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '9px',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: '#616161',
  },
  borderRadius: '15px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
}));

const TokenDetails = styled(CardContent)({
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
});

const TokenIcon = styled(Avatar)({
  width: '40px',
  height: '40px',
  marginRight: '10px',
});

const TokenStats = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '10px',
  fontSize: '0.8rem',
  paddingTop: '10px',
  borderTop: '1px solid #ccc',
});

const CustomContainer = styled(Container)(({ theme }) => ({
  maxWidth: 'xl',
  [theme.breakpoints.up('xl')]: {
    maxWidth: '2600px',
  },
}));

const calculatePercentage = (amount, total) => (amount / total) * 100;

const calculateActiveDuration = (createdAt) => {
  const createdDate = new Date(createdAt);
  const currentDate = new Date();
  const hoursActive = Math.floor((currentDate - createdDate) / (1000 * 60 * 60));
  if (hoursActive < 24) {
    return `${hoursActive} hours`;
  } else {
    const daysActive = Math.floor(hoursActive / 24);
    return `${daysActive} days`;
  }
};

function SearchResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tokens, setTokens] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [solPrice , setSolPrice] = useState(180);
  const [selectedToken, setSelectedToken] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [estimatedTokens, setEstimatedTokens] = useState(0);
  const [estimatedSolReceived, setEstimatedSolReceived] = useState(0);
  const [topTokens, setTopTokens] = useState([]);
  const [topHolders, setTopHolders] = useState([]);
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [chartType, setChartType] = useState('Line');
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);
  const [buyError, setBuyError] = useState(false);
  const [sellError, setSellError] = useState(false);
  const { publicKey, signTransaction } = useWallet();
  
  
  const tokensPerPage = 100; // Set the number of tokens per page
  const [page, setPage] = useState(1); // Manage the current page state
  // Calculate the paginated tokens
  const paginatedTokens = tokens.slice((page - 1) * tokensPerPage, page * tokensPerPage);

  useEffect(() => {
    const getSolPrice = async () => {
      try {
        const price = await fetchAndCacheSolPrice();
        setSolPrice(price);
      } catch (error) {
        console.error('Error getting SOL price:', error);
      }
    };

    getSolPrice();
  }, []);

   // Retrieve the query from location.state
   const query = location.state?.query || '';

  console.log('Search Query: ', query);
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get(`https://backend.memebrewery.fun/api/tokens`, {
          params: {
            search: query,
            page: currentPage,
            pageSize: 100,  // Adjust page size as needed
          }
        });

        setTokens(response.data.tokens);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    if (query) {
      fetchTokens();
    }
  }, [query, currentPage]);

  const fetchTokens = async (query = '', page = 1, pageSize = 100) => {
    try {
      const token = localStorage.getItem('auth-token');
  
      // Construct the URL correctly based on whether a query is provided
      const baseUrl = 'https://backend.memebrewery.fun/api/tokens';
      const url = `${baseUrl}?page=${page}&pageSize=${pageSize}${query ? `&${query}` : ''}`;
  
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      console.log('Response data:', response.data);
  
      if (!response.data || !response.data.tokens) {
        throw new Error('Invalid response structure');
      }
  
      const { tokens, totalPages, currentPage } = response.data;
  
      setTokens(tokens);
      setTotalPages(totalPages);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };  

  const fetchTokenData = async (tokenId) => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}`, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching token data:', error);
      return null;
    }
  };

  // const fetchTopTokens = async () => {
  //   try {
  //     const token = localStorage.getItem('auth-token');
  //     const response = await axios.get('https://backend.memebrewery.fun/api/top-tokens', {
  //       withCredentials: true,
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });
  //     setTopTokens(response.data);
  //   } catch (error) {
  //     console.error('Error fetching top tokens:', error);
  //   }
  // };

  // const fetchTopHolders = async () => {
  //   try {
  //     const token = localStorage.getItem('auth-token');
  //     const response = await axios.get('https://backend.memebrewery.fun/api/top-holders', {
  //       withCredentials: true,
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });
  //     setTopHolders(response.data);
  //   } catch (error) {
  //     console.error('Error fetching top holders:', error);
  //   }
  // };

  const fetchHistoricalData = async (tokenId) => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}/history`, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setHistoricalData(response.data.historicalData);
    } catch (error) {
      console.error('Error fetching historical data:', error);
    }
  };

  const fetchTransactions = async (tokenId) => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.get(`https://backend.memebrewery.fun/api/transactions/${tokenId}`, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}` },
      });
      setTransactions(response.data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };


  const handleCardClick = (token) => {
    setSelectedToken(token);
    fetchHistoricalData(token.id);
    fetchTransactions(token.id); 
    setMessage('');
  };

  const handlePurchase = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/buy', { tokenId: selectedToken.id, amount: purchaseAmount }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { transaction: base64Transaction, tokenId, tokensReceived  } = response.data;
      const transaction = Transaction.from(Buffer.from(base64Transaction, 'base64'));
      const signedTransaction = await signTransaction(transaction);
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');

      await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        tokenId: selectedToken.id,
        amount: purchaseAmount,
        phase: 'buy'
      }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      setMessage('Purchase successful!');
      setSnackbarOpen(true);
      setSelectedToken(null);
      setPurchaseAmount(0);

      // Reset modal state
      setSelectedToken(null);
      setPurchaseAmount(0);
      setEstimatedTokens(0);

      // Refresh token list to show recent updates
      await fetchTokens();

    } catch (error) {
      setMessage('Purchase failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const handleSell = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/sell', { tokenId: selectedToken.id, amount: sellAmount }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { transaction: base64Transaction } = response.data;
      const transaction = Transaction.from(Buffer.from(base64Transaction, 'base64'));
     
      const signedTransaction = await signTransaction(transaction);
      
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');

      await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        tokenId: selectedToken.id,
        amount: sellAmount,
        phase: 'sell'
      }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      setMessage('Sale successful!');
      setSnackbarOpen(true);
      setSelectedToken(null);
      setSellAmount(0);

      // Reset modal state
      setSelectedToken(null);
      setSellAmount(0);
      setEstimatedSolReceived(0);

      // Refresh token list to show recent updates
      await fetchTokens();
      
    } catch (error) {
      setMessage('Sale failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const handlePurchaseAmountChange = async (e) => {
    const amountInSol = e.target.value;
    setPurchaseAmount(amountInSol);
    setBuyError(amountInSol === '' || isNaN(amountInSol) || parseFloat(amountInSol) <= 0);
  
    const parsedAmount = parseFloat(amountInSol);
  
    if (selectedToken && !isNaN(parsedAmount)) {
      const tokenData = await fetchTokenData(selectedToken.id);
  
      if (tokenData) {
        const { availableSupply, price } = tokenData; 
        const tokens = calculateTokensReceived(parsedAmount, availableSupply, A, N);  
        setEstimatedTokens(tokens);
        setSelectedToken(tokenData);
      } else {
        setEstimatedTokens(0);
      }
    } else {
      setEstimatedTokens(0);
    }
  };

  const handleSellAmountChange = (e) => {
    const amountToSell = e.target.value;
    setSellAmount(amountToSell);
    setSellError(amountToSell === '' || isNaN(amountToSell) || parseFloat(amountToSell) <= 0);

    const parseSellAmount = parseFloat(amountToSell);
  
    if (selectedToken) {
      const estimatedSol = parseSellAmount * selectedToken.price;
      setEstimatedSolReceived(estimatedSol.toFixed(6));
    }
  };  
  
  const handleLaunchToken = () => {
    navigate('/create-token');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchTokens('', newPage, 100); 
    }
  };

  
  const chartData = {
    labels: historicalData.map(data => data.date),
    datasets: [
      {
        label: selectedToken ? selectedToken.name : 'Token Data',
        data: historicalData.map(data => data.price),
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
  };

  const renderChart = () => {
    switch (chartType) {
      case 'Line':
        return <Line data={chartData} options={chartOptions} />;
      case 'Bar':
        return <Bar data={chartData} options={chartOptions} />;
      case 'Doughnut':
        return <Doughnut data={chartData} options={chartOptions} />;
      case 'Pie':
        return <Pie data={chartData} options={chartOptions} />;
      case 'Radar':
        return <Radar data={chartData} options={chartOptions} />;
      case 'PolarArea':
        return <PolarArea data={chartData} options={chartOptions} />;
      case 'Bubble':
        return <Bubble data={chartData} options={chartOptions} />;
      case 'Scatter':
        return <Scatter data={chartData} options={chartOptions} />;
      default:
        return <Line data={chartData} options={chartOptions} />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomContainer>
        {/* Back Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Button 
            onClick={() => navigate('/')} 
            variant="contained" 
            color="primary" 
            startIcon={<ArrowBackIcon />} 
            sx={{ fontWeight: 'bold', boxShadow: 2 }}
          >
            Back to Home
          </Button>
        </Box>

        {/* Search Results Content */}
        <Typography variant="h4" component="h1" gutterBottom>
          Search Results
        </Typography>
        <Box display="flex" flexDirection="column" style={{ marginTop: 20 }}>
        <Grid container spacing={3} style={{ flex: 3 }}>
            {paginatedTokens.map((token) => (
              <Grid item key={token.id} xs={12} sm={6} md={4} lg={3} style={{ marginBottom: 10 }}>
                <TokenCard onClick={() => handleCardClick(token)}>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <TokenIcon src={token.icon} alt={`${token.name} icon`} style={{ width: 60, height: 60 }} />
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      {token.website && (
                        <IconButton href={token.website} target="_blank" size="small">
                          <LanguageIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                        </IconButton>
                      )}
                      {token.twitter && (
                        <IconButton href={token.twitter} target="_blank" size="small">
                          <TwitterIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                        </IconButton>
                      )}
                      {token.telegram && (
                        <IconButton href={token.telegram} target="_blank" size="small">
                          <TelegramIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  <TokenDetails>
                    <Typography variant="subtitle1" component="h2" style={{ color: 'white', fontWeight: 'bold' }}>
                      {token.name} ({token.symbol})
                    </Typography>
                    <TokenDescription>
                      {token.description || 'No description available'}
                    </TokenDescription>
                  </TokenDetails>
                  <TokenStats>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" alignItems="center">
                        <TrendingUpIcon style={{ color: '#00ff00', marginRight: 5 }} />
                        <Typography style={{ color: '#00ff00', fontWeight: 'bold' }}>
                          {parseFloat(token.liquiditySol).toFixed(5)} SOL ({calculatePercentage(parseFloat(token.liquiditySol), 400).toFixed(6)}%)
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <AccountBalanceWalletIcon style={{ color: theme.palette.text.secondary, marginRight: 5 }} />
                        <Typography style={{ color: theme.palette.text.secondary }}>
                          {token.transactionCount || 0} txns
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <TrendingUpIcon style={{ color: theme.palette.text.secondary, marginRight: 5 }} />
                        <Typography style={{ color: theme.palette.text.secondary }}>
                          ${parseFloat(token.marketVolume).toFixed(6) || 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" marginTop="10px">
                      <Box display="flex" alignItems="center">
                        <HourglassEmptyIcon style={{ color: theme.palette.text.secondary, marginRight: 5 }} />
                        <Typography style={{ color: theme.palette.text.secondary }}>
                          {calculateActiveDuration(token.createdAt) || 0}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <MonetizationOnIcon style={{ color: theme.palette.text.secondary, marginRight: 5 }} />
                        <Typography style={{ color: theme.palette.text.secondary }}>
                          Mkt: ${(token.supply * token.price * solPrice).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </TokenStats>
                </TokenCard>
              </Grid>
            ))}
          </Grid>

          {/* Pagination Component */}
          <Box display="flex" justifyContent="center" mt={4}>
            <CustomPagination
              count={Math.ceil(tokens.length / tokensPerPage)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>

        {/* Pagination */}
        {totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ '& .MuiPaginationItem-root': { color: 'white' } }}
            />
          </Box>
         )}

        {selectedToken && (
          <Dialog
            open={Boolean(selectedToken)}
            onClose={() => setSelectedToken(null)}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              Buy or Sell {selectedToken.name}
            </DialogTitle>
            <DialogContent>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }} // Stack vertically on small screens
                alignItems="flex-start"
              >
                <Box
                  width={{ xs: '100%', md: '50%' }} // Full width on small screens, half on larger
                  mb={3}
                >
                  {selectedToken.banner && (
                    <BannerImage
                      src={selectedToken.banner}
                      alt={`${selectedToken.name} banner`}
                      style={{ width: '100%' }} // Make sure the image scales properly
                    />
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width={{ xs: '100%', md: '50%' }} // Adjust width based on screen size
                  ml={{ md: 3 }} // Margin only applied on medium and up screens
                >
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="chart-type-label">Chart Type</InputLabel>
                    <Select
                      labelId="chart-type-label"
                      value={chartType}
                      onChange={(e) => setChartType(e.target.value)}
                    >
                      <MenuItem value="Line">Line</MenuItem>
                      <MenuItem value="Bar">Bar</MenuItem>
                      <MenuItem value="Doughnut">Doughnut</MenuItem>
                      <MenuItem value="Pie">Pie</MenuItem>
                      <MenuItem value="Radar">Radar</MenuItem>
                      <MenuItem value="PolarArea">PolarArea</MenuItem>
                      <MenuItem value="Bubble">Bubble</MenuItem>
                      <MenuItem value="Scatter">Scatter</MenuItem>
                    </Select>
                  </FormControl>
                  <Box height={{ xs: 300, md: 400 }} mt={3}>
                    {renderChart()}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} mt={4}>
                <Box flex={1} mb={{ xs: 3, md: 0 }}>
                  <Typography variant="h6" gutterBottom>
                    Buy {selectedToken.name}
                  </Typography>
                  <TextField
                    label="Amount of SOL to Spend"
                    type="text"
                    value={purchaseAmount}
                    onChange={handlePurchaseAmountChange}
                    error={buyError}
                    helperText={buyError ? 'Please enter a valid amount' : ''}
                    fullWidth
                    margin="normal"
                    
                  />
                  <Typography variant="body1">
                    Estimated Tokens Received: {estimatedTokens}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePurchase}
                    fullWidth
                    style={{ marginTop: '10px' }}
                  >
                    Buy
                  </Button>
                </Box>
                <Box flex={1} ml={{ md: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Sell {selectedToken.name}
                </Typography>
                <TextField
                  label="Amount to Sell"
                  type="number"
                  value={sellAmount}
                  onChange={handleSellAmountChange}
                  error={sellError}
                  helperText={sellError ? 'Please enter a valid amount' : ''}
                  inputProps={{ min: 1 }}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Avatar
                          src={selectedToken.icon} // Assuming the icon URL is stored in selectedToken.icon
                          alt={`${selectedToken.name} icon`}
                          sx={{ width: 24, height: 24 }} // Size of the icon
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  Estimated SOL Received: {estimatedSolReceived} SOL
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSell}
                  fullWidth
                  style={{ marginTop: '10px' }}
                >
                  Sell
                </Button>
              </Box>
              </Box>
              <TokenDetailsTabs tokenId={selectedToken.id} />
              {/* <Box width="100%" mt={4}>
                <Typography variant="h6" gutterBottom>
                  Transaction History
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactions.map((tx) => (
                        <TableRow key={tx.id}>
                          <TableCell>{new Date(tx.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell>{tx.type}</TableCell>
                          <TableCell>{tx.amount}</TableCell>
                          <TableCell> <FormattedValue 
                                value={tx.price.toString()} 
                                superscriptIndex={5} // Adjust as needed for your formatting
                            /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedToken(null)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
       
      </CustomContainer>
    </ThemeProvider>
  );
}

export default SearchResults;
