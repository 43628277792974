import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  WalletConnectWalletAdapter,
  LedgerWalletAdapter,
  TorusWalletAdapter,
  TrustWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import axios from 'axios';
import '@solana/wallet-adapter-react-ui/styles.css';

export const WalletContext = createContext();

export const WalletContextProvider = ({ children }) => {
  const [address, setAddress] = useState(null);
  const [user, setUser] = useState(null);
  const [wallet, setWallet] = useState(null);

  const network = process.env.REACT_APP_SOLANA_NETWORK || WalletAdapterNetwork.MainnetBeta;

  const endpoint = useMemo(() => {
    switch (network) {
      case 'mainnet-beta':
        return process.env.REACT_APP_MAINNET_RPC_URL || clusterApiUrl(WalletAdapterNetwork.MainnetBeta);
      case 'testnet':
        return process.env.REACT_APP_TESTNET_RPC_URL || clusterApiUrl(WalletAdapterNetwork.Testnet);
      case 'devnet':
      default:
        return process.env.REACT_APP_DEVNET_RPC_URL || clusterApiUrl(WalletAdapterNetwork.Devnet);
    }
  }, [network]);

  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new WalletConnectWalletAdapter(),
    new LedgerWalletAdapter(),
    new TorusWalletAdapter(),
    new TrustWalletAdapter()
  ], [network]);

  const authenticateUser = useCallback(async (walletAddress) => {
    console.log('Attempting to authenticate user with wallet address:', walletAddress);
    try {
      const response = await axios.post('https://backend.memebrewery.fun/api/auth/login', { walletAddress });
      console.log('Authentication response:', response.data);
      if (response.data.token) {
        console.log('Token received, setting in localStorage and axios headers');
        localStorage.setItem('auth-token', response.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        setUser(response.data.user);
        console.log('User set in state:', response.data.user);
      } else {
        console.log('No token received in the response');
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }
  }, []);

  const handleConnect = useCallback(async (wallet) => {
    if (wallet.publicKey) {
      const walletAddress = wallet.publicKey.toString();
      console.log('Wallet connected:', walletAddress);
      setAddress(walletAddress);
      await authenticateUser(walletAddress);
    }
  }, [authenticateUser]);

  const handleDisconnect = useCallback(async () => {
    console.log('Wallet disconnected');
    setAddress(null);
    setUser(null);
    localStorage.removeItem('auth-token');
    delete axios.defaults.headers.common['Authorization'];

    try {
      await axios.post('https://backend.memebrewery.fun/api/auth/logout');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }, []);

  useEffect(() => {
    if (wallet && wallet.connected) {
      handleConnect(wallet);
    } else {
      handleDisconnect();
    }
  }, [wallet, handleConnect, handleDisconnect]);

  useEffect(() => {
    const walletInstance = wallets[0];
    setWallet(walletInstance);

    const connectHandler = () => handleConnect(walletInstance);
    const disconnectHandler = () => handleDisconnect();
    const accountChangeHandler = (publicKey) => {
      if (publicKey) {
        handleConnect(walletInstance);
      } else {
        handleDisconnect();
      }
    };

    if (walletInstance.readyState === 'Installed') {
      walletInstance.connect().catch(() => {});
    }

    walletInstance.on('connect', connectHandler);
    walletInstance.on('disconnect', disconnectHandler);
    walletInstance.on('accountChanged', accountChangeHandler);

    if (walletInstance.connected) {
      handleConnect(walletInstance);
    }

    return () => {
      walletInstance.off('connect', connectHandler);
      walletInstance.off('disconnect', disconnectHandler);
      walletInstance.off('accountChanged', accountChangeHandler);
    };
  }, [wallets, handleConnect, handleDisconnect]);

  const signTransaction = async (transaction) => {
    if (!wallet.signTransaction) {
      throw new Error('signTransaction is not available in the wallet');
    }
    return wallet.signTransaction(transaction);
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <WalletContext.Provider value={{ 
            address, 
            user, 
            wallet, 
            handleConnect, 
            signTransaction, 
            handleDisconnect, 
            setUser 
          }}>
            {children}
          </WalletContext.Provider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
