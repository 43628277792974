// utils/solPriceCache.js
import axios from 'axios';

const SOL_PRICE_CACHE_KEY = 'solPriceCache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_PRICE = 180.00;

export const fetchAndCacheSolPrice = async () => {
  const cachedData = localStorage.getItem(SOL_PRICE_CACHE_KEY);
  
  if (cachedData) {
    const { price, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_DURATION) {
      // Return default price if the cached price is zero or extremely small
      return price > 0.000000 ? parseFloat(price).toFixed(2) : DEFAULT_PRICE.toFixed(2);
    }
  }

  try {
    const response = await axios.get('https://backend.memebrewery.fun/api/sol-price', { withCredentials: true });
    let newPrice = response.data.solPrice;

    // If the fetched price is too small or zero, use the default price
    if (newPrice <= 0.000000) {
      newPrice = DEFAULT_PRICE;
    }

    newPrice = parseFloat(newPrice).toFixed(2); // Ensure it's in fixed-point notation

    // Store the price in cache
    localStorage.setItem(SOL_PRICE_CACHE_KEY, JSON.stringify({
      price: newPrice,
      timestamp: Date.now()
    }));

    return newPrice;
  } catch (error) {
    console.error('Error fetching SOL price:', error);
    return DEFAULT_PRICE.toFixed(2);
  }
};

