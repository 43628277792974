// AuthContext.js
import React, { createContext, useContext } from 'react';
import { WalletContext } from './WalletContext';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const walletContext = useContext(WalletContext);
  
  if (!walletContext) {
    console.error('WalletContext is not available');
    return null; // or return a loading state
  }

  const { user, setUser } = walletContext;

  const authenticateUser = async (walletAddress) => {
    try {
      const response = await axios.post('https://backend.memebrewery.fun/api/auth/login', { walletAddress });
      if (response.data.token) {
        localStorage.setItem('auth-token', response.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        setUser(response.data.user);
      }
    } catch (error) {
      console.error('Authentication failed:', error);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('auth-token');
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, authenticateUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};