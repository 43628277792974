import React, { useEffect } from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Meme Brewery Terms and Conditions</h1>
      
      <h2>Introduction</h2>
      <p>Meme Brewery is the premier platform to discover and launch the next trending token (“Collection”). All Collection creations are conducted fairly – there are no pre-sales, no insider advantages, and no manipulative practices. Each Collection has a fixed supply of one (1) billion tokens.</p>
      
      <h2>Token Creation</h2>
      <p>You create each token by initiating a smart contract on a selected public blockchain. Once created, the token’s code becomes self-executing and cannot be altered by you or anyone else. These smart contracts undergo a full audit by Decentrys Technology <a href="https://decentrys.technology">https://decentrys.technology</a>. Additionally, the supply and trading dynamics of any token on Meme Brewery adhere to fixed transfer and exchange rules established by the blockchain protocol. These rules apply to all creators and tokens and cannot be arbitrarily changed to prevent token sales. However, be aware that prices may still collapse – this is the nature of meme tokens, and you accept all associated risks when participating. You agree not to hold Meme Brewery liable for any money you may spend and lose on tokens.</p>

      <h2>Usage Monitoring</h2>
      <p>If Meme Brewery detects or suspects that you are using the software interface for fraudulent purposes, in violation of any law, or in a manner that breaches these Terms and Conditions, Meme Brewery reserves the right to alter information related to your token or remove public access to the token at any time, at the sole discretion of the Interface Providers (defined below).</p>

      <h2>Interface Providers</h2>
      <p>“Interface Providers” refers to the entities and individuals that provide the interface for accessing and using Meme Brewery. This includes the software developers, administrators, and any affiliated parties responsible for the platform’s maintenance and operation.</p>

      <h2>Acknowledgment and Acceptance</h2>
      <ul>
        <li>Risk of Loss: You understand and accept the high-risk nature of meme tokens. Meme Brewery is not responsible for any losses incurred.</li>
        <li>Regulatory Compliance: You agree to comply with all applicable laws and regulations. You are solely responsible for ensuring your actions are lawful.</li>
        <li>Fraud Prevention: Meme Brewery reserves the right to monitor and take action against any fraudulent or illegal activities, including but not limited to, removing access to tokens or altering token information.</li>
        <li>Immutable Contracts: Once created, smart contracts cannot be altered by any party. You acknowledge the permanence of these contracts and their conditions.</li>
        <li>No Liability: Meme Brewery and its Interface Providers are not liable for any losses, damages, or claims arising from your use of the platform.</li>
      </ul>

      <h2>Binding Agreement</h2>
      <p>You agree that by accessing the Collections Interface, you have read, understood, and agreed to be bound by all of these Terms of Use. If you do not agree with these Terms, you agree not to use the Collections Interface. You also agree not to hold the Interface Providers responsible for any loss you suffer from engaging with the Collections Interface.</p>
      <p><strong>IN ADDITION, YOU MAY NOT USE OUR SITE IF YOU:</strong></p>
      <ul>
        <li>ARE NOT THE OLDER OF (i) AT LEAST EIGHTEEN (18) YEARS OF AGE; OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT; OR</li>
        <li>ARE A CITIZEN OR RESIDENT OF, OR LOCATED IN, ANY COUNTRY WHERE THE USE OF THE SITE IS ILLEGAL OR IMPERMISSIBLE OR WHICH WOULD SUBJECT US TO ANY REGISTRATION REQUIREMENT WITHIN SUCH JURISDICTION; OR</li>
        <li>ARE OTHERWISE PROHIBITED FROM ACCESSING OR USING THIS SITE OR ANY OF THIS SITE’S FUNCTIONALITIES BY THESE TERMS OR BY APPLICABLE LAW.</li>
      </ul>

      <h2>Access to and Use of Meme Brewery</h2>
      <p>To access and use the Meme Brewery interface, you will need to connect your token wallet. You are solely responsible for the security of your wallet. You must only operate your wallet with a private key(s) that you created. You must treat the private key(s) as confidential and take reasonable measures to prevent disclosure of that information to anyone else.</p>
      <p>If you launch a token using the Meme Brewery interface, you agree and promise:</p>
      <ul>
        <li>All content that you upload to the Site is your creation or under your ownership and you affirm and warrant that the content does not violate anyone’s intellectual property rights;</li>
        <li>The content is not obscene, hateful, or inciting of violence to any individual or group;</li>
        <li>You will not describe the token in a way that an individual or group will engage in any activity, such as adding utility to the token, that is likely to generate long-term value for the token Collection;</li>
        <li>You will not engage with the Site through automated or non-human means, whether through a bot, script, or otherwise;</li>
        <li>You will not circumvent or disable security features of, or attack, the Site or any of the Site’s underlying blockchain networks or technologies;</li>
        <li>You will not introduce any material that is malicious or technologically harmful to the Site, its users, any underlying blockchain, or any of the Site’s related utilities or functionalities;</li>
        <li>You will not attempt to trick, defraud, manipulate, or mislead, and will not cause harm to, others;</li>
        <li>You will not disparage or harm the reputation of the Site or its owner;</li>
        <li>You will not share illegal, harmful, fraudulent, or offensive content; use private information of third parties without consent; disclose confidential information without permission; or encourage or induce others to engage in prohibited or illegal activities;</li>
        <li>You will not use the Site to engage in illegal activities or to circumvent any laws.</li>
      </ul>
      <p>You acknowledge that once a token is created, neither you nor the Interface Providers can undo its creation; however, the Interface Providers reserve the right to remove your token from visibility, priority, or integration with the Meme Brewery Interface at any time for any reason.</p>

      <h2>Interface Provider Obligations</h2>
      <p>We strive to keep the Meme Brewery Interface available at all times, but access may be suspended for maintenance, for protection from attacks or other threats, or due to events beyond our reasonable control. Online support for the Site is available at [support@memebrewery.cc].</p>

      <h2>Modifications and Interruptions</h2>
      <p>We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.</p>

      <h2>Fees</h2>
      <p>The Interface Providers will collect transaction fees on all trades conducted through the Site. We reserve the right to alter those fees at our discretion at any time.</p>

      <h2>Privacy Policy</h2>
      <p>By using the Site, you agree to be bound by our Privacy Policy posted on the Site. Please be advised the Site is based in the US. If you access the Site from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the US then through your continued use of the Site, you are transferring your data to [x], and you agree to have your data transferred to and processed in the US subject to applicable local laws and regulations.</p>

      <h2>Term and Termination</h2>
      <p>These Terms shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, AT ANY TIME, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, LIMIT, DENY, OR TERMINATE YOUR ACCESS TO AND USE OF THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED. If we block your access to the Site for any reason, you are prohibited from circumventing it, even if you may be acting on behalf of a third party. We reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>

      <h2>Blockchain Risks</h2>
      <p>Blockchain technologies carry a relatively high amount of foreseeable and unforeseeable risk from security, financial, technical, political, social, and personal safety standpoints. You understand that crypto-assets, including tokens, may have no present or future value and are highly volatile in nature due to many diverse factors, including without limitation use and adoption, speculation, manipulation, technology, security, and legal and regulatory developments and application. Furthermore, the speed and cost of transacting with cryptographic technologies, such as blockchains, are variable and highly volatile. You understand that the transparent nature of many blockchains means that any interactions you have with a blockchain may be publicly visible and readable in human form.</p>
      <p>You represent that you are familiar with the underlying blockchain technologies behind the tokens, and the risks of buying them, which include but are not limited to vulnerability to hacking and total loss of your funds.</p>

      <h2>Warranty Disclaimer</h2>
      <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR ANY THIRD-PARTY CONTENT AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR ACCESS TO AND USE OF THE SITE OR OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE OR AS A RESULT OF A THIRD PARTY’S INTERACTIONS WITH THE SITE.</p>

      <p>No professional advice: The content and information presented on or through the Site is made available solely for informational purposes and should not be construed as professional advice (including, without limitation, tax, legal, or financial advice).</p>

      <p>No fiduciary duty: These Terms are not intended to create any fiduciary duties between us and you or any third party. The Interface Providers never take possession, custody, control, ownership, or management of any crypto-assets or other property transmitted via the Site. To the fullest extent permissible by law, you agree that your use of the Site does not cause us or any other user to owe fiduciary duties or liabilities to you or any third party, and if any such duties or liabilities are afforded by law or by equity, they are hereby irrevocably disclaimed, waived, and eliminated to the fullest extent permissible by law.</p>

      <h2>Limitation of Liability</h2>
      <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL WE, OUR AFFILIATES, OR OUR OR THEIR DIRECTORS, OFFICERS, EMPLOYEES, REPRESENTATIVES, OR AGENTS (“INTERFACE PROVIDERS OR AFFILIATES”) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
      <p>AS BETWEEN MEME BREWERY, YOU AGREE THAT MEME BREWERY PROVIDES THE TECHNICAL INTEGRATION TO THE SMART CONTRACTS AND INDIVIDUALS’ TOKEN WALLETS AND HOLDS MEME BREWERY HARMLESS FOR ANY VULNERABILITIES OR EXPLOITS THEREIN. NOTWITHSTANDING THIS DISCLAIMER, YOU AGREE TO HOLD MEME BREWERY HARMLESS FOR ANY SUCH VULNERABILITY OR EXPLOIT.</p>

      <h2>Indemnification</h2>
      <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties outlined in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; (5) any overt harmful act toward any other user of the Site; (6) your use or reliance on of any information obtained from the Site; or (7) any other party’s access and use of the Site with your assistance or by using any device or account that your own or control. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding that is subject to this indemnification upon becoming aware of it.</p>

      <h2>Dispute Resolution</h2>
      <p>You agree that any dispute under these Terms will be resolved under the laws and in the courts of the United States.</p>

      <h2>Miscellaneous</h2>
      <p><strong>OFAC Regulations</strong>: The Site may be subject to sanctions laws and regulations of the United States and other jurisdictions. You represent that you are not named on any U.S. or E.U government denied-party or generally-sanctioned jurisdiction list. You agree that you are not listed as a specially designated national (SDN) by OFAC. You will not, nor will you permit any other users to, access or use the Site in a U.S.-sanctioned country (including Cuba, Iran, North Korea, Sudan, Syria, or Crimea (region of Ukraine)) or violation of any U.S. sanctions, export law or regulation. Meme Brewery will cooperate with any sanctions or financial crime enforcement action or investigation of users, as may be required by law, solely within Meme Brewery’s discretion.</p>
      <p><strong>Taxes</strong>: You are solely responsible for identifying, calculating, reporting, and remitting to the appropriate tax authorities any and all taxes related to your token(s) that may apply to your use of Meme Brewery.</p>
      <p><strong>Severability</strong>: If any provision of these Terms is held by a court of competent jurisdiction to be contrary to law, the remaining provisions will remain in full force and effect.</p>
      <p><strong>Waiver</strong>: The waiver of a breach of any term hereof will in no way be construed as a waiver of any other term or breach hereof.</p>
      <p><strong>Entire Agreement</strong>: These Terms are the entire understanding of the parties concerning their subject matter and supersede any previous or contemporaneous communications, whether oral or written.</p>
      <p><strong>Headings and Interpretation</strong>: Headings and the summary explanations at the beginning of each Section of these Terms are for reference only and do not affect the parties’ rights and obligations hereunder. As used herein, “may” means “has the right, but not the obligation, to,” and “includes” and its variations mean “includes, but is not limited to.”</p>
      <p><strong>Electronic Communications, Transactions, and Signatures</strong>: Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email, and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction that require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</p>
    </div>
  );
};

export default TermsAndConditions;