import React, { useState } from 'react';
import {
  AppBar, Toolbar, Box, Button, TextField, InputAdornment, Dialog,
  DialogTitle, DialogContent, DialogActions, Typography, useMediaQuery, useTheme
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

const FilterButton = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      marginRight: 2,
      backgroundColor: '#616161',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#757575',
      },
      fontSize: { xs: '0.75rem', sm: '1rem' },
      padding: { xs: '0.5rem 0.75rem', sm: '0.75rem 1rem' },
      textAlign: 'center',
    }}
  >
    {children}
  </Button>
);

const HowItWorksButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor: 'secondary.main',
      color: 'text.primary',
      '&:hover': {
        backgroundColor: 'secondary.dark',
      },
      fontSize: { xs: '0.75rem', sm: '1rem' },
      padding: { xs: '0.5rem 0.75rem', sm: '0.75rem 1rem' },
      textAlign: 'center',
      marginRight: 1, // Padding before the button
    }}
  >
    How this works
  </Button>
);

const LaunchTokenButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor: '#00bfa5',
      color: 'text.primary',
      '&:hover': {
        backgroundColor: '#00897b',
      },
      fontSize: { xs: '0.75rem', sm: '1rem' },
      padding: { xs: '0.5rem 0.75rem', sm: '0.75rem 1rem' },
      textAlign: 'center',
      marginRight: 1, // Padding before the button
    }}
  >
    Launch your Token!
  </Button>
);

export default function MarketplaceAppBar({ handleFilter, handleLaunchToken, handleSearchSubmit }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const [howItWorksOpen, setHowItWorksOpen] = useState(false);

  const handleHowItWorksOpen = () => {
    setHowItWorksOpen(true);
  };

  const handleHowItWorksClose = () => {
    setHowItWorksOpen(false);
  };

  const filterButtonStyles = {
    backgroundColor: '#424242',
    color: '#ffffff',
    fontSize: isMobile ? '0.75rem' : '0.875rem',
    padding: isMobile ? '6px 8px' : '8px 12px',
    marginRight: '5px',
  };

  const actionButtonStyles = {
    backgroundColor: '#616161',
    color: '#ffffff',
    padding: isMobile ? '6px 8px' : '8px 12px',
    fontSize: isMobile ? '0.75rem' : '0.875rem',
    marginRight: '10px',
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar
          sx={{
            justifyContent: isMobile ? 'center' : 'space-between',
            flexWrap: 'wrap',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            padding: '10px 20px',  // Added padding around the toolbar
          }}
        >
          {/* Filter Buttons for Mobile */}
          {isMobile && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: 1,
              }}
            >
              <Button onClick={() => handleFilter('new')} sx={filterButtonStyles}>
                <NewReleasesIcon fontSize="small" />
                New
              </Button>
              <Button onClick={() => handleFilter('top')} sx={filterButtonStyles}>
                <StarIcon fontSize="small" />
                Top
              </Button>
              <Button onClick={() => handleFilter('final')} sx={filterButtonStyles}>
                <CheckCircleIcon fontSize="small" />
                Final
              </Button>
            </Box>
          )}

          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  flexWrap: 'nowrap',
                }}
              ></Box>
              <Button onClick={() => handleFilter('new')} sx={filterButtonStyles}>
                <NewReleasesIcon fontSize="small" />
                New
              </Button>
              <Button onClick={() => handleFilter('trending')} sx={filterButtonStyles}>
                <TrendingUpIcon fontSize="small" />
                Trending
              </Button>
              <Button onClick={() => handleFilter('rising')} sx={filterButtonStyles}>
                <ShowChartIcon fontSize="small" />
                Rising
              </Button>
              <Button onClick={() => handleFilter('top')} sx={filterButtonStyles}>
                <StarIcon fontSize="small" />
                Top
              </Button>
              <Button onClick={() => handleFilter('final')} sx={filterButtonStyles}>
                <CheckCircleIcon fontSize="small" />
                Final
              </Button>
            </Box>
          )}

          {/* Search Bar */}
          {isMobile && isLandscape ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: 1,
                paddingBottom: 1, // Padding for better spacing
              }}
            >
              <Box
                component="form"
                onSubmit={handleSearchSubmit}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '90%', // Adjusted width for better centering
                  gap: 1,
                  paddingTop: 1,  // Added padding above the search bar
                }}
              >
                <TextField
                  name="search"
                  variant="outlined"
                  size="small"
                  placeholder="Search tokens..."
                  sx={{
                    flexGrow: 1,
                    minWidth: '150px',
                    '& input': { color: 'black' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: 'black' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" variant="contained" color="primary">
                  <SearchIcon />
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSearchSubmit}
              sx={{
                display: 'flex',
                maxWidth: isMobile ? '100%' : '300px',  // Make the search bar smaller on desktop
                width: isMobile ? '90%' : '100%', // Adjusted width for mobile devices
                gap: 1,
                marginLeft: 'auto',
                padding: '0 10px', // Added padding around the search bar
              }}
            >
              <TextField
                name="search"
                variant="outlined"
                size="small"
                placeholder="Search tokens..."
                sx={{
                  flexGrow: 1,
                  minWidth: '150px',
                  '& input': { color: 'black' },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: 'black' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained" color="primary">
                <SearchIcon />
              </Button>
            </Box>
          )}

          {/* How It Works & Launch Token Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              paddingTop: 1, // Added padding for better spacing
            }}
          >
            <Button
              onClick={handleHowItWorksOpen}
              sx={{
                ...actionButtonStyles,
                backgroundColor: '#ff4081',
                marginRight: '5px',  // Padding before the button
              }}
            >
              How this works
            </Button>
            <Button
              component={Link}
              to="/create-token"
              sx={{
                ...actionButtonStyles,
                backgroundColor: '#00bfa5',
                marginRight: '5px',  // Padding before the button
              }}
            >
              Launch your Token!
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* "How It Works" Dialog */}
      <Dialog open={howItWorksOpen} onClose={handleHowItWorksClose} maxWidth="sm" fullWidth>
        <DialogTitle>How Meme Brewery Works</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Meme Brewery is the best place to discover (and launch) the next trending token!
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Fair Launch</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>No pre-sale, no insiders, max 1B supply</li>
              <li>Ownership renounced, immutable</li>
              <li>Fully audited smart contracts</li>
              <li>Buy and sell at any time</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>When Brewery?</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>Once market cap reaches 400 SOL all remaining tokens and liquidity migrate to Raydium</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Launch your token on Meme Brewery</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>Takes just a few seconds ✅</li>
              <li>Free Enhanced Token Info! ✅</li>
              <li>Only ~0.02 SOL to deploy ✅</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Disclaimer</strong></Typography>
          <Typography variant="body2" gutterBottom>
            Tokens launched on this platform are not endorsed by Meme Brewery. Meme Brewery is not an investment platform; your tokens could lose significant value at any time. By using Meme Brewery, you acknowledge all risks involved and agree to our Terms and Conditions.
          </Typography>
          <Link to="/terms" color="secondary">Terms and Conditions</Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHowItWorksClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
