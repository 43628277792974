import React, { useEffect, useState } from 'react';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Paper, Tabs, Tab, Pagination, useMediaQuery, useTheme, styled
} from '@mui/material';
import axios from 'axios';
import FormattedValue from './FormattedValue';

// Customizing Tabs for a modern look
const CustomTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1rem',
  padding: '12px 24px',
  borderRadius: '8px',
  transition: 'all 0.3s ease',
  '&.Mui-selected': {
    backgroundColor: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
    color: theme.palette.background.paper,
    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
  },
  '&:not(.Mui-selected)': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  '&:hover': {
    backgroundColor: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%)`,
    color: theme.palette.background.paper,
  },
}));

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

function TokenDetailsTabs({ tokenId }) {
  const [value, setValue] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [topTraders, setTopTraders] = useState([]);
  const [holders, setHolders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1); // Reset to the first page when switching tabs
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (value === 0) {
          const response = await axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}/transactions`, {
            params: { page: currentPage, pageSize: 10 }
          });
          setTransactions(response.data.transactions);
          setTotalPages(response.data.totalPages);
        } else if (value === 1) {
          const response = await axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}/top-traders`, {
            params: { page: currentPage, pageSize: 10 }
          });
          setTopTraders(response.data.topTraders);
          setTotalPages(response.data.totalPages);
        } else if (value === 2) {
          const response = await axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}/holders`, {
            params: { page: currentPage, pageSize: 10 }
          });
          setHolders(response.data.holders);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [value, currentPage, tokenId]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Box width="100%" mt={isMobile ? 2 : 4} px={isMobile ? 2 : 4}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        aria-label="token details tabs"
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "on" : "off"}
        sx={{ marginBottom: isMobile ? 1 : 3 }}
      >
        <CustomTab label="Transaction History" />
        {/* <CustomTab label="Top Wallet Traders" />
        <CustomTab label="All Holders" /> */}
      </CustomTabs>

      {value === 0 && (
        <Box>
          <Typography variant="h6" gutterBottom sx={{ fontSize: isMobile ? '1rem' : '1.25rem', color: theme.palette.primary.main }}>
            Transaction History
          </Typography>
          <CustomTableContainer component={Paper}>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Date</CustomTableCell>
                  <CustomTableCell>Type</CustomTableCell>
                  <CustomTableCell>Amount</CustomTableCell>
                  <CustomTableCell>Price</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((tx) => (
                  <TableRow key={tx.id}>
                    <CustomTableCell>{new Date(tx.createdAt).toLocaleDateString()}</CustomTableCell>
                    <CustomTableCell>{tx.type}</CustomTableCell>
                    <CustomTableCell>{tx.amount}</CustomTableCell>
                    <CustomTableCell><FormattedValue 
                                value={tx.price.toString()} 
                                superscriptIndex={5} // Adjust as needed for your formatting
                            /></CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </Box>
      )}

      {value === 1 && (
        <Box>
          <Typography variant="h6" gutterBottom sx={{ fontSize: isMobile ? '1rem' : '1.25rem', color: theme.palette.primary.main }}>
            Top Wallet Traders
          </Typography>
          <CustomTableContainer component={Paper}>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Rank</CustomTableCell>
                  <CustomTableCell>Address of Maker</CustomTableCell>
                  <CustomTableCell>Bought</CustomTableCell>
                  <CustomTableCell>Sold</CustomTableCell>
                  <CustomTableCell>PNL</CustomTableCell>
                  <CustomTableCell>Balance</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topTraders.map((trader, index) => (
                  <TableRow key={trader.address}>
                    <CustomTableCell>{index + 1}</CustomTableCell>
                    <CustomTableCell>{trader.address}</CustomTableCell>
                    <CustomTableCell>{trader.bought}</CustomTableCell>
                    <CustomTableCell>{trader.sold}</CustomTableCell>
                    <CustomTableCell>{trader.pnl}</CustomTableCell>
                    <CustomTableCell>{trader.balance}</CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </Box>
      )}

      {value === 2 && (
        <Box>
          <Typography variant="h6" gutterBottom sx={{ fontSize: isMobile ? '1rem' : '1.25rem', color: theme.palette.primary.main }}>
            All Holders
          </Typography>
          <CustomTableContainer component={Paper}>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Rank</CustomTableCell>
                  <CustomTableCell>Wallet Address</CustomTableCell>
                  <CustomTableCell>Percentage of Token</CustomTableCell>
                  <CustomTableCell>Amount</CustomTableCell>
                  <CustomTableCell>Value</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holders.map((holder) => (
                  <TableRow key={holder.address}>
                    <CustomTableCell>{holder.rank}</CustomTableCell>
                    <CustomTableCell>{holder.address}</CustomTableCell>
                    <CustomTableCell>{holder.percentage}%</CustomTableCell>
                    <CustomTableCell>{holder.amount}</CustomTableCell>
                    <CustomTableCell>{holder.value} SOL</CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </Box>
      )}
      
      {/* Pagination */}
      {totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                color: 'white',
                fontSize: isMobile ? '0.75rem' : '1rem',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default TokenDetailsTabs;
