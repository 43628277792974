import React from 'react';

function FormattedValue({ value }) {
    let numericValue;

    if (typeof value === 'number') {
        numericValue = value;
    } else if (typeof value === 'string') {
        numericValue = parseFloat(value);
    }

    if (isNaN(numericValue)) {
        // If the value can't be converted to a number, return it as is
        return <span>{value} SOL</span>;
    }

    // Convert the number to a string with enough precision to avoid scientific notation
    let valueStr = numericValue.toFixed(12).replace(/\.?0+$/, '');
    
    const decimalIndex = valueStr.indexOf('.');

    if (decimalIndex === -1) {
        // If there's no decimal point, return the value as is
        return <span>{valueStr} SOL</span>;
    }

    const beforeDecimal = valueStr.slice(0, decimalIndex);
    const firstThreeZeros = valueStr.slice(decimalIndex, decimalIndex + 4); // '.000'
    const remainingDecimals = valueStr.slice(decimalIndex + 4);

    return (
        <span>
            {beforeDecimal}
            <sub>{firstThreeZeros}</sub>
            {remainingDecimals} SOL
        </span>
    );
}

export default FormattedValue;
